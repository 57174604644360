import commonHelper from '@/app/utility/common.helper.utility';
import purReqEntryForm from './purReqEntryForm';
import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';

export default {
  name: 'purchaseRequisition',
  watch: {},
  components: { purReqEntryForm, DatePicker },
  data() {
    return {
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      operationUnit: {
        text: null,
        value: null
      },
      requiNum: null,
      preparer: null,
      buyer: null,
      requester: null,
      item: {
        text: null,
        value: null
      },
      status: {
        text: null,
        value: null
      },
      dateFrom: null,
      dateTo: null,
      purchaseRequisitionData: [],
      purchaseRequisitionFields: [
        {
          key: 'requisition_org',
          label: 'Requisition Org',
          class: 'text-center'
        },
        {
          key: 'number',
          label: 'Number',
          class: 'text-center'
        },
        {
          key: 'line',
          label: 'Line',
          class: 'text-center'
        },
        {
          key: 'item',
          label: 'Item',
          class: 'text-center'
        },
        {
          key: 'description',
          label: 'Description',
          class: 'text-center'
        },
        {
          key: 'category',
          label: 'Category',
          class: 'text-center'
        },
        {
          key: 'quantity',
          label: 'Quantity',
          class: 'text-center'
        },
        {
          key: 'uom',
          label: 'UOM',
          class: 'text-center'
        },
        {
          key: 'price',
          label: 'Price',
          class: 'text-center'
        },
        {
          key: 'amount',
          label: 'Amount',
          class: 'text-center'
        },
        {
          key: 'requester',
          label: 'Requester',
          class: 'text-center'
        },
        {
          key: 'note_to_buyer',
          label: 'Note To Buyer',
          class: 'text-center'
        },
        {
          key: 'justification',
          label: 'Justification',
          class: 'text-center'
        }
      ],
      showPurReqEntryModal: false,
      accessButtons: {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: true,
        addAllow: true,
        printAllow: false,
        approvalAllow: false
      },
      unsubscribe: null,
      showValueSetModal: false,
      rowData: null
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.rowData = null;
          this.showPurReqEntryModal = true;
        }
      }
    });
  },
  methods: {
    showHidePurReqEntryModal(flag) {
      this.showPurReqEntryModal = flag;
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.dateFrom);
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.EWAY_BILL_DOC_TYPE) {
        this.docType = {
          text: item.value_meaning,
          value: item.value_code
        };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.operationUnit.value) {
        this.operationUnit = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.requiNum) {
        this.requiNum = null;
      } else if (vsetCode === this.preparer) {
        this.preparer = null;
      } else if (vsetCode === this.buyer) {
        this.buyer = null;
      } else if (vsetCode === this.requester) {
        this.requester = null;
      } else if (vsetCode === this.item.value) {
        this.item = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.status.value) {
        this.status = {
          text: null,
          value: null
        };
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
